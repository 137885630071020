$(document).ready(initPage);
function initPage() {
  $('.m-search-toggler').click(function () {
    $('.topbar-m-search').toggleClass('is-active');
    $('.topbar-m-menu').removeClass('is-active');
    if ($('.topbar-m-search').hasClass('is-active')) {
      $('.header').addClass('is-active');
    } else {
      $('.header').removeClass('is-active');
    }
  });
  $('.m-menu-toggler').click(function () {
    $('.topbar-m-menu').toggleClass('is-active');
    $('.topbar-m-search').removeClass('is-active');
    if ($('.topbar-m-menu').hasClass('is-active')) {
      $('.header').addClass('is-active');
      $('body').addClass('is-m-menu-open');
    } else {
      $('.header').removeClass('is-active');
      $('body').removeClass('is-m-menu-open');
    }
  });

  $('.search-input').on('input', function () {
    var inputValue = $(this).val();
    if (inputValue.length > 0) {
      $('.header').addClass('is-search');
    } else {
      $('.header').removeClass('is-search');
    }
  });
  $('.search-bg').click(function () {
    $('.header').removeClass('is-search');
  });

  $('.open-popup-link').magnificPopup({
    type: 'inline',
    closeMarkup:
      '<button title="%title%" type="button" class="mfp-close"></button>',
    callbacks: {
      open: function () {
        // Add a class to the body when the popup is opened
        $('html').addClass('is-popup-open');
      },
      close: function () {
        // Remove the class from the body when the popup is closed
        $('html').removeClass('is-popup-open');
      },
    },
  });
}
